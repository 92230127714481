import * as React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import LogoStacked from 'components/Logo/LogoStacked';
import { Footer } from 'components/Footer';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      body: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      },
      '#root': {
        // inherit background color to prevent axe warning
        // "Element's background color could not be determined because it's partially obscured by another element"
        backgroundColor: 'inherit',
        position: 'fixed',
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: theme.spacing(0, 3),
        display: 'grid',
        gridTemplateAreas: `"content"
                        "footer"`,
        gridTemplateRows: '1fr min-content',
        gridTemplateColumns: '100%',
        [theme.breakpoints.up('sm')]: {
          padding: theme.spacing(0, 7.5),
        },
        // target touchscreens
        '@media (hover: none) and (pointer: coarse)': {
          overflowY: 'scroll', // has to be scroll for overflow scrolling
          overflowScrolling: 'touch',
        },
      },
    },
    main: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      maxWidth: '500px',
      margin: '100px auto auto',
      [theme.breakpoints.up('sm')]: {
        margin: '100px auto auto',
      },
      [theme.breakpoints.up('md')]: {
        margin: 'auto',
        paddingTop: '8vh',
        paddingBottom: '8vh',
      },
    },
    content: {
      display: 'inherit',
      flexDirection: 'inherit',
      alignItems: 'inherit',
      width: '100%',
      minHeight: '20vh',
    },
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(4),
      },
    },
    logo: {
      maxWidth: '250px',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '300px',
      },
    },
    footer: {
      width: '100%',
    },
  }),
);

export interface LayoutProps {
  title?: string;
  titleImageSrc?: string;
}

export const Layout: React.FC<LayoutProps> = (props) => {
  const { title, titleImageSrc } = props;
  const classes = useStyles(props);

  let titleContent = (
    <Typography variant="h1" aria-label="Anthology" className={classes.title}>
      <LogoStacked className={classes.logo} />
    </Typography>
  );

  if (title) {
    if (titleImageSrc) {
      titleContent = (
        <Typography variant="h1" aria-label="Anthology" className={classes.title}>
          <img src={titleImageSrc} alt={title} className={classes.logo} />
        </Typography>
      );
    } else {
      titleContent = (
        <Typography variant="h1" className={classes.title}>
          {title}
        </Typography>
      );
    }
  }

  return (
    <>
      <main className={classes.main}>
        {titleContent}
        <div className={classes.content}>{props.children}</div>
      </main>
      <Footer />
    </>
  );
};

export default Layout;
